import { Collections } from '../config/collection'
import { auth, db } from '../config/firebase'
import { ClearLocalStorage, unsubscribeList } from '../config/utils'
import { loadProgress, searchingHotelMessages } from '../constants/general'
import { actions } from '../Store'
import URL from '../constants/apiUrls'
import Axios from '../utility/axiosHelper'

export const GetHotelData = (subDomain, dispatch) => {
  try {
    const collectionKey = `GetHotelData${Collections.DOMAIN}${subDomain}`

    if (!subDomain || unsubscribeList?.[collectionKey]) return

    const unSub = db
      .collection(Collections.DOMAIN)
      .where('subDomain', '==', subDomain)
      .where('isDelete', '==', false)
      .onSnapshot(domainSnapshot => {
        let domainInfo = null
        let hotelLogo = null
        let hotelWallpaper = null
        let subDomainNotFound = true
        let searchDomain = {
          searchingHotel: '',
          searchingHotelMessage: '',
        }

        if (domainSnapshot.docs.length < 1) {
          searchDomain['searchingHotelMessage'] =
            searchingHotelMessages.NOT_AVAILABLE
        } else {
          for (let domain of domainSnapshot.docs) {
            domainInfo = {
              id: domain.id,
              hotelId: domain.id,
              ...domain.data(),
            }

            if (domainInfo) {
              hotelLogo = domainInfo.hotelLogo
              hotelWallpaper = domainInfo.hotelWallpaper
              subDomainNotFound = false
              localStorage.setItem('hotel', subDomain)
              searchDomain['searchingHotelMessage'] =
                searchingHotelMessages.SUCCESS
            } else {
              ClearLocalStorage()
              searchDomain['searchingHotelMessage'] =
                searchingHotelMessages.NOT_AVAILABLE
            }
          }
        }
        searchDomain['searchingHotel'] = loadProgress.LOADED
        dispatch(
          actions.setHotelData({
            subDomain: domainInfo?.subDomain || subDomain,
            hotelInfo: domainInfo,
            subDomainNotFound: subDomainNotFound,
            hotelLogo,
            hotelWallpaper,
            ...searchDomain,
          })
        )
      })

    unsubscribeList[collectionKey] = unSub
  } catch (error) {
    console.log(error)
  }
}

export const GetHotelDataFromId = (hotelId, dispatch, addedListenerStatus) => {
  try {
    const collectionKey = `GetHotelDataFromId${Collections.HOTELS}${hotelId}`
    if (
      !auth?.currentUser ||
      addedListenerStatus ||
      unsubscribeList?.[collectionKey]
    )
      return
    dispatch(actions.setGetHotelDataFromIdListener(true))
    const unSub = db
      .collection(Collections.HOTELS)
      .doc(hotelId)
      .onSnapshot(hotelRef => {
        let hotelInfo = { id: hotelId, ...hotelRef.data() }
        if (hotelInfo?.isDelete || hotelInfo?.status === 'inactive') {
          ClearLocalStorage()
          dispatch(
            actions.setClearHotelUserData({
              searchingHotel: loadProgress.LOADED,
              searchingHotelMessage: searchingHotelMessages.NOT_AVAILABLE,
            })
          )
        } else {
          dispatch(actions.setHotelInfo(hotelInfo))
          dispatch(
            actions.setSearchingHotel({
              searchingHotel: loadProgress.LOADED,
              searchingHotelMessage: searchingHotelMessages.SUCCESS,
            })
          )
        }
      })

    unsubscribeList[collectionKey] = unSub
  } catch (error) {
    console.log(error)
  }
}

export const GetHotelDataByKey = async subDomain => {
  try {
    let hotelData = null
    if (subDomain) {
      const domainSnapshot = await db
        .collection(Collections.DOMAIN)
        .where('subDomain', '==', subDomain)
        .where('isDelete', '==', false)
        .limit(1)
        .get()

      for (const domain of domainSnapshot.docs) {
        hotelData = {
          id: domain.id,
          hotelId: domain.id,
          ...domain.data(),
        }
      }
    }
    return hotelData
  } catch (error) {
    console.log(error)
  }
}

export const hotelGuideLinesListener = (dispatch, hotelId) => {
  try {
    const collectionKey = `hotelGuideLinesListener${Collections.HOTELS}${hotelId}${Collections.HOTELGUIDELINES}${hotelId}`

    if (
      !auth?.currentUser ||
      unsubscribeList[collectionKey] ||
      !hotelId ||
      !auth.currentUser
    )
      return

    const unSub = db
      .collection(Collections.HOTELS)
      .doc(hotelId)
      .collection(Collections.HOTELGUIDELINES)
      .doc(hotelId)
      .onSnapshot(doc => {
        dispatch(
          actions.setHotelGuideLines({
            data: doc?.data()?.files || [],
            loadingStatus: loadProgress.LOADED,
          })
        )
      })
    unsubscribeList[collectionKey] = unSub
  } catch (error) {
    console.log({ error })
    console.log(error?.message)
    return { success: false, message: error?.message }
  }
}

export function getHotelOverallFeedbackQuestions(
  hotelId = '',
  questionId = ''
) {
  let dbQuery = db
    .collection(Collections.OVERALLFEEDBACKQUESTIONS)
    .doc(hotelId)
    .collection(Collections.HOTELOVERALLFEEDBACKQUESTIONS)

  if (questionId) {
    dbQuery = dbQuery.doc(questionId)
  }
  return dbQuery
}

export const hotelOverAllFeedbackQuestionListener = (dispatch, hotelId) => {
  let collectionKey = `hotelOverAllFeedbackQuestionListener`
  if (!hotelId || unsubscribeList[collectionKey]) return
  const setFunc = actions.setOverAllFeedbackQuestion

  try {
    dispatch(setFunc({ loadingStatus: loadProgress.LOADING }))
    let sub = db
      .collection(Collections.OVERALLFEEDBACKQUESTIONS)
      .doc(hotelId)
      .collection(Collections.HOTELOVERALLFEEDBACKQUESTIONS)
      .where('isDelete', '==', false)
      .onSnapshot(snapShot => {
        let data = {}

        for (const doc of snapShot.docs) {
          data[doc.id] = { id: doc.id, ...doc.data() }
        }

        dispatch(setFunc({ data, loadingStatus: loadProgress.LOADED }))
      })

    unsubscribeList[collectionKey] = sub
  } catch (error) {
    console.log({ error })
    console.log(error?.message)
    dispatch(setFunc({ loadingStatus: loadProgress.LOADED }))
    return { success: false, message: error?.message }
  }
}

export const GetHotelDoors = async ({ hotelId, currentLanguage }) => {
  try {
    const data = await Axios.post(
      URL.GET_HOTEL_DOORS,
      { hotelId },
      { 'Accept-Language': currentLanguage }
    )
    return data
  } catch (error) {
    console.log(error?.message)
    return { success: false, message: error?.message }
  }
}

export const openGuestDoor = async ({ hotelId, doorId, currentLanguage }) => {
  try {
    const data = await Axios.post(
      URL.OPEN_GUEST_DOOR,
      { hotelId, doorId },
      { 'Accept-Language': currentLanguage }
    )
    return data
  } catch (error) {
    console.log(error?.message)
    return { success: false, message: error?.message }
  }
}

export const hotelDoorListener = (dispatch, hotelId) => {
  let collectionKey = `hotelDoorListener`
  if (!hotelId || unsubscribeList[collectionKey]) return
  try {
    let sub = db
      .collection(Collections.FLEXIPASSHOTELSETTINGS)
      .doc(hotelId)
      .onSnapshot(snapShot => {
        const response = snapShot.data()
        if (response) {
          dispatch(actions.setHotelDoorPresent(true))
          localStorage.setItem('hotelDoorPresent', true)
        } else {
          localStorage.setItem('hotelDoorPresent', false)
          dispatch(actions.setHotelDoorPresent(false))
        }
      })

    unsubscribeList[collectionKey] = sub
  } catch (error) {
    console.log({ error })
    console.log(error?.message)
    return { success: false, message: error?.message }
  }
}

export const fetchPmsIntegrationType = async hotelId => {
  try {
    // Query to get pmsIntegrationType from the hotels collection
    const hotelDoc = await db.collection(Collections.HOTELS).doc(hotelId).get()
    const pmsIntegrationType = hotelDoc.data()?.pmsIntergationType // Accessing the pmsIntegrationType
    return pmsIntegrationType
  } catch (error) {
    console.error('Error fetching PMS Integration Type:', error)
  }
}
